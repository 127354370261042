<template>
  <div class="value-container">
    <div v-for="(week, weekKey) in value" :key="weekKey" class="week-container">
      <div class="week-header">
        <h3 class="week-title">{{ `Week ${weekKey.split('_')[1]}` }}</h3>
        <span @click="removeWeek(weekKey)" class="btn btn-remove-week" title="Remove Week"><i class="fa fa-times" aria-hidden="true"></i></span>
      </div>
      <div v-for="(slot, slotIndex) in week" :key="slotIndex" class="slot-container">
        <select v-model="slot.day" class="slot-input form-control" @change="checkDuplicateDay(weekKey, slotIndex)">
          <option v-for="day in availableDays(weekKey, slotIndex)" :key="day" :value="day">{{ day }}</option>
        </select>
        <input type="time" v-model="slot.start_time" class="slot-input form-control">
        <input type="time" v-model="slot.end_time" class="slot-input form-control">
        <span @click="removeSlot(weekKey, slotIndex)" class="btn btn-remove" title="Remove"><i class="fa fa-trash" aria-hidden="true"></i></span>
      </div>
      <span @click="addSlot(weekKey)" class="btn btn-add" :disabled="week.length >= 7 || availableDays(weekKey).length === 0">+ Add Slot</span>
      <p v-if="availableDays(weekKey).length === 0 && week.length < 7" class="error-message">All days of the week are used</p>
    </div>
    <div class="text-center">
      <span @click="addWeek" class="btn btn-add-week" :disabled="!canAddNewWeek">
      {{ Object.keys(value).length === 0 ? 'Add Available Slots' : 'Add More Week' }}
    </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    }
  },
  data() {
    return {
      days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    }
  },
  mounted(){
  },
  computed: {
    canAddNewWeek() {
      const weekKeys = Object.keys(this.value);
      if (weekKeys.length === 0) return true;
      const latestWeek = this.value[weekKeys[weekKeys.length - 1]];
      return latestWeek.length > 0;
    }
  },
  methods: {
    addSlot(weekKey) {
      if (!this.value[weekKey]) {
        this.$set(this.value, weekKey, []);
      }
      const availableDays = this.availableDays(weekKey);
      if (this.value[weekKey].length < 7 && availableDays.length > 0) {
        this.value[weekKey].push({
          day: availableDays[0],
          start_time: "10:00",
          end_time: "11:00"
        });
      }
    },
    removeSlot(weekKey, slotIndex) {
      this.value[weekKey].splice(slotIndex, 1);
      if (this.value[weekKey].length === 0) {
        this.$delete(this.value, weekKey);
      }
    },
    addWeek() {
      if (this.canAddNewWeek) {
        const newWeekIndex = Object.keys(this.value).length + 1;
        this.$set(this.value, `week_${newWeekIndex}`, []);
        // Add a default slot to the new week
        this.addSlot(`week_${newWeekIndex}`);
      }
    },
    removeWeek(weekKey) {
      this.$delete(this.value, weekKey);
    },
    availableDays(weekKey, excludeIndex = -1) {
      const usedDays = this.value[weekKey]
        .map((slot, index) => index !== excludeIndex ? slot.day : null)
        .filter(day => day !== null);
      return this.days.filter(day => !usedDays.includes(day));
    },
    checkDuplicateDay(weekKey, slotIndex) {
      const currentSlot = this.value[weekKey][slotIndex];
      const duplicateIndex = this.value[weekKey].findIndex(
        (slot, index) => index !== slotIndex && slot.day === currentSlot.day
      );
      if (duplicateIndex !== -1) {
        this.value[weekKey][duplicateIndex].day = this.availableDays(weekKey, slotIndex)[0];
      }
    }
  },
}
</script>


<style scoped lang="scss">
.time-input {
  /* Your custom styles here */
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #fff;
}

/* Styling the dropdown arrow */
.time-input::-webkit-calendar-picker-indicator {
  background: #ffffff;
  cursor: pointer;
  padding: 0;
}

/* Hover effect */
.time-input:hover {
  border-color: #999;
}

/* Focus effect */
.time-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}
.week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-family:'MyriadProRegular', 'Lato', sans-serif;
}
.error-message {
  color: red;
  font-size: 0.9em;
}
.btn-remove-week {
  background-color: #b41818;
  color: #fffcc4;
  &:hover{background-color:red;}
}
.error-message {
  color: red;
  font-size: 0.9em;
}
.value-container {
  max-width: 800px;
  margin: 0 auto;
  font-family:'MyriadProRegular', 'Lato', sans-serif;
}

.value-title {
  color: #fffcd6;
  text-align: center;
}

.week-container {
  background-color: rgba(137, 137, 137, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.week-title {
  color: #fffcd6;
  font-size: 18px;
  margin: 0;
}

.slot-container {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items:center;
}
.slot-input.form-control {
    height: auto;
    padding: 5px 15px;
    background: rgba(255, 255, 255, 0.1);
    border-color: transparent;
    font-size: 16px;
    color: #fff;
    font-family: 'MyriadProRegular', 'Lato', sans-serif;
    transition: all 0.7s cubic-bezier(0.42, 1.14, 1, 1);
    box-shadow: none !important;
    cursor:pointer;
    option{color:#000000;}
}
.btn {
  padding:6px 12px;
  border: none !important;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  // transition: background-color 0.3s;
  box-shadow:none !important;
}

.btn-remove {
  background-color: #ff4d4d;
  color: white;
  &:hover {
    background-color: #ff3333;
  }
}

.btn-add {
  background-color: #28a745;
  color: white;
  &:hover {
  background-color: #028609;
  }
}

.btn-add-week {
  display: inline-block;
  margin: 0 auto;
  background: none;
  border: 2px solid #ffe400 !important;
  font-size: 18px;
  padding: 12px 8px;
  line-height: 1;
  height: auto;
  min-width: 210px;
  border-radius: 6px;
  box-shadow: none !important;
  outline: none !important;
  color: #ffe400;
  font-weight: normal;
  &:hover{
    background: #ffe400;
    color: #000000;
  }
}

.value-json {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
  font-size: 14px;
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>